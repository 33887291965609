<template>
    <header>
        <nav>
            <ul>
                <li><router-link to="/">Home Page</router-link></li>
                <li><router-link to="/bear-of-the-day">Bear of the Day</router-link></li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
    name: 'HeaderBar'
}
</script>

<style scoped>
header {
    background-color: #f4f4f4;
    padding: 10px;
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    display: inline;
    margin-right: 10px;
}
</style>