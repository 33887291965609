import { createRouter, createWebHistory } from 'vue-router'
import BearOfTheDay from '../components/BearOfTheDayWrapper.vue'
import HomePage from '../components/HomePage.vue'

const routes = [
    {
        path: '/bear-of-the-day',
        component: BearOfTheDay
    },
    {
      path: '/home',
      component: HomePage
    },
    {
      path: '/',
      component: HomePage
    },
    {
      path: '/:catchAll(.*)',
      component: HomePage
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router