<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App'
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #774b08;
  margin-top: 60px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; /* Or use a background image */
}

.header-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}
</style>
