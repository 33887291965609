<template>
    <div class="container">
        <HeaderBar class="header-bar"/>
        <BearOfTheDay/>
    </div>
</template>

<script>
import HeaderBar from './HeaderBar.vue'
import BearOfTheDay from '../../projects/bear-of-the-day/frontend/src/App.vue'


export default {
  name: 'BearOfTheDayWrapper',
  components: {
    HeaderBar,
    BearOfTheDay
  }
}
</script>

<style scoped>

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    display: inline;
    margin-right: 10px;
}
</style>