<template>
    <div class="container">
        <HeaderBar class="header-bar"/>
        <main class="content">
            <h1 class="title">Handcrafted AI</h1>
            <p class="description">More will be added soon.</p>
        </main>
    </div>
</template>

<script>
import HeaderBar from './HeaderBar.vue'

export default {
  name: 'HomePage',
  components: {
    HeaderBar
  }
}
</script>

<style scoped>


.content {
    text-align: center;
    padding: 20px;
}

.title {
    font-size: 2.5em;
    font-family: 'Arial', sans-serif; /* Use a custom font */
}

.description {
    font-size: 1.5em;
}
nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    display: inline;
    margin-right: 10px;
}
</style>